<template>
  <div>
    <div class="login-links__languages">
      <div @click="setLanguage('en')" :class="{ active: $i18n.locale == 'en' }">
        EN
      </div>
      <div @click="setLanguage('es')" :class="{ active: $i18n.locale == 'es' }">
        ES
      </div>
      <div @click="setLanguage('pt')" :class="{ active: $i18n.locale == 'pt' }">
        PT
      </div>
    </div>
    <br>
    <div class="text-wrap" v-if="$i18n.locale == 'pt'">
      Política de Privacidade<br /><br />
      Somos a FLUKE DO BRASIL LTDA., Empresa devidamente constituída e existente
      de acordo com as leis do Brasil, com sede na Avenida Maria Coelho Aguiar,
      nº. 215, Bloco F, Andar Panamby, Loja 84C, Jardim São Luís, CEP 05805-000,
      na cidade de São Paulo, Estado de São Paulo, inscrito na contribuinte sob
      o nº 02.370.758 / 0001-47 (“Fluke” , "Nós" ou "nosso").<br /><br />
      A sua privacidade e a segurança dos seus dados pessoais são muito
      importantes para nós. Nesta Política de Privacidade (ou simplesmente
      "Política"), explicamos quais informações e dados pessoais coletamos dos
      usuários do aplicativo Assistente de Vendas Virtual e todos os seus
      subdomínios, programas e extensões ("aplicativo VSA"), por que coletamos,
      medidas de segurança e outras informações relevantes.<br /><br />
      Preparamos uma tabela com uma breve descrição dos termos desta Política.
      Não descreve todo o conteúdo desta Política, mas apenas um resumo.
      Portanto, leia a Política completa para entender como gerenciamos e
      protegemos sua privacidade e dados pessoais ao usar o aplicativo VSA.<br /><br />
      Tabela de resumo:<br />
      1. Escopo de aplicação e data efetiva<br />
      Explicamos a quem esta Política se aplica e a data de entrada em vigor da
      Política.<br /><br />
      2 Informações que coletamos de você e objetivos<br />
      Explicamos as categorias de informações e dados pessoais que coletamos dos
      usuários do aplicativo VSA, que são estritamente necessários para usar o
      aplicativo e quais não são, por que os coletamos e como os usamos.<br /><br />
      3. Compartilhe seus dados pessoais<br />
      Explicamos que podemos compartilhar seus dados e informações com: Empresas
      Fluke fora do Brasil; técnicas de operação de provedores de serviços e
      infraestrutura relacionadas ao aplicativo VSA; Polícia e autoridades de
      proteção de dados; e um terceiro somente com o consentimento prévio do
      Usuário. Por favor, leia esta seção para mais informações.<br /><br />
      4. Correção, retenção e exclusão de dados<br />
      Explicamos como corrigir ou atualizar quaisquer dados pessoais, o período
      de retenção de suas informações e as situações para sua exclusão.<br /><br />
      5. Transferência para outros países<br />
      Explicamos em quais situações seus dados pessoais podem ser transferidos
      para outros países.<br /><br />
      6. Segurança e proteção<br />
      Explicamos as medidas que tomamos para salvaguardar os seus dados
      pessoais.<br /><br />
      7. Seus direitos<br />
      Listamos todos os direitos de dados pessoais concedidos a pessoas físicas
      no Brasil pela legislação brasileira<br /><br />
      8. Mudanças nesta política<br />
      Explicamos o que acontecerá em caso de alteração dos termos desta
      Política.<br /><br />
      9. Como entrar em contato conosco<br />
      Fornecemos nossos dados de contato.<br /><br />
      1. Escopo de aplicação e data efetiva<br />
      Esta Política é aplicável a você se você for um usuário de aplicativo VSA
      no Brasil. Acesse estes Termos de Uso para entender as condições
      aplicáveis ​​para usar o aplicativo VSA.<br /><br />
      O aplicativo VSA foi desenvolvido para os parceiros de vendas da Fluke no
      Brasil e na América Latina, incluindo distribuidores e representantes de
      vendas, e não se destina a menores de 18 anos, portanto, não coletamos nem
      vendemos dados de menores.<br /><br />
      Esta Política de Privacidade entra em vigor em 10 de fevereiro de 2020.<br /><br />
      2. Informações que coletamos e propósitos<br />
      Nesta seção, você encontrará as categorias de dados pessoais e informações
      que coletaremos de você e do uso do aplicativo VSA em seu dispositivo e
      suas finalidades. E você também aprenderá quais deles você realmente
      precisa fornecer para usar o aplicativo e quais são opcionais.<br /><br />
      É importante que você compreenda que tem opções em relação aos dados
      pessoais que coletamos. Você pode controlar quais informações fornecerá ou
      não, dependendo do que deseja do aplicativo VSA.<br /><br />
      Nós retemos os dados e informações do usuário apenas enquanto sua conta
      estiver ativa ou conforme necessário para lhe fornecer serviços. Também
      retemos e usamos suas informações conforme necessário para cumprir nossas
      obrigações legais.<br /><br />
      Informações que os usuários nos fornecem diretamente<br />
      Abaixo, você encontrará os dados e informações pessoais que pode nos
      fornecer: Dados necessários para usar o aplicativo VSA<br /><br />
      Categorias de dados:<br /><br />
      DADOS DO USUÁRIO<br />
      Nome e sobrenome<br />
      Endereço de email<br />
      Senha<br /><br />
      Objetivos: coletamos essas informações para que você possa criar uma conta
      de usuário, acessar o aplicativo VSA, habilitar a navegação no aplicativo,
      habilitar o uso dos serviços fornecidos pelo VSA e entrar em contato com
      você. Sem fornecer as informações acima, não será possível acessar ou usar
      o aplicativo VSA.<br /><br />
      Dados para aumentar sua experiência com o aplicativo VSA<br /><br />
      Categoria de dados:<br /><br />
      DADOS DO USUÁRIO<br />
      Objetivos:<br />
      Número de telefone<br />
      Nome e localização da empresa (país, código postal, cidade, estado)<br />
      Função na empresa<br />
      Número de telefone adicional<br /><br />
      DADOS DO DISPOSITIVO DO USUÁRIO E USO DO APLICATIVO<br />
      Câmera<br />
      Agenda telefônica<br />
      Impressão digital<br />
      Network<br />
      Chamadas telefônicas<br /><br />
      Coletamos essas informações para aprimorar sua experiência e habilitar
      recursos e funcionalidades adicionais do aplicativo VSA, como ligar para
      um número de telefone diretamente do aplicativo.<br /><br />
      Essas informações não são necessárias para usar o aplicativo VSA, mas
      podem aumentar sua experiência de uso.<br /><br />
      Sempre pediremos seu consentimento antes de usar esses dados.<br /><br />
      Informações que coletamos automaticamente do uso do aplicativo VSA<br /><br />
      Abaixo, você encontrará os dados pessoais e as informações que coletamos
      automaticamente de qualquer usuário do aplicativo VSA:<br />
      Dados necessários para usar o aplicativo VSA<br /><br />
      Categorias de dados:<br /><br />
      INFORMAÇÕES TÉCNICAS E DE USO<br />
      Endereço IP, local e hora de acesso<br />
      Informações do dispositivo (tipo, ID, modelo e fabricante)<br /><br />
      Finalidades da versão do aplicativo:<br /><br />
      Coletamos essas informações para permitir a navegação no aplicativo e o
      cumprimento das obrigações legais.<br />
      Dados para aumentar sua experiência com o aplicativo VSA<br /><br />
      Categorias de dados:<br /><br />
      INFORMAÇÕES TÉCNICAS E DE USO<br />
      Cookies e tecnologias semelhantes para rastrear o uso do aplicativo<br />
      Objetivos: coletamos essas informações para mantê-lo conectado, lembrar
      suas preferências e identificar você e seu dispositivo.<br />
      Sempre solicite seu consentimento antes de usar esses dados.<br /><br />
      Controle de seus dados pessoais<br />
      Você pode gerenciar seus dados para gerenciar como a Fluke usa seus dados
      pessoais, atualizando-os, excluindo parte deles ou excluindo sua conta.<br /><br />
      Acesse seu perfil no painel do VSA.<br /><br />
      Sobre seus dados<br />
      É importante esclarecer que, de acordo com a legislação brasileira, “dados
      pessoais” são quaisquer informações relacionadas a uma pessoa física
      identificada ou identificável. Alguns dados coletados por nós, como nome,
      e-mail e endereço IP (Protocolo de Internet), são dados pessoais.<br />
      No entanto, não inclui informações agregadas não pessoalmente
      identificáveis ​​que não identifiquem um usuário ou que não possam ser
      razoavelmente vinculadas ou de outra forma conectadas. Podemos usar essas
      informações agregadas não pessoalmente identificáveis ​​para operar,
      analisar, melhorar e otimizar o aplicativo VSA.<br /><br />
      3. Compartilhe seus dados pessoais<br />
      Podemos compartilhar os dados pessoais ou informações privadas do usuário
      com:<br /><br />
      O grupo Fluke de empresas fora do Brasil, incluindo Fluke Corporation, com
      sede em 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, e
      distribuidores Fluke locais na América Latina, com o objetivo de verificar
      se o usuário faz parte do canal de vendas da Fluke América Latina e
      conceder acesso ao aplicativo, bem como determinar as informações locais
      relevantes sobre férias e promoções e que possam ser compartilhadas com os
      Usuários do aplicativo;<br /><br />
      Prestadores de serviços que operam técnicos e de infraestrutura
      relacionados para o aplicativo VSA, como segurança e serviços de TI, desde
      que aceitem restrições de privacidade semelhantes às desta Política de
      Privacidade, assinando acordos de proteção de dados ou compromissos
      semelhantes;<br /><br />
      Polícia e autoridades de proteção de dados, para cumprir as obrigações
      legais;<br /><br />
      E/ou terceiros somente com o consentimento prévio do Usuário.<br /><br />
      Não vendemos seus dados pessoais ou informações privadas.<br /><br />
      4. Correção, retenção e exclusão de dados<br />
      Caso deseje corrigir, atualizar ou excluir alguma informação pessoal,
      entre em contato com marketing@fluke.com.br.<br /><br />
      Mesmo após a exclusão de seus dados pessoais, podemos mantê-los usando
      medidas seguras para cumprir as obrigações legais ou em outra situação
      permitida pela legislação brasileira.<br /><br />
      5. Transferência para outros países<br />
      Podemos transferir os dados pessoais ou informações privadas do Usuário
      para outros países para:<br /><br />
      Compartilhe-os com o grupo Fluke de empresas fora do Brasil, incluindo a
      Fluke Corporation sediada em 6920 Seaway Blvd., Everett, WA 98203, Estados
      Unidos, e distribuidores Fluke locais na América Latina, a fim de
      verificar se o usuário está parte do canal de vendas da Fluke para a
      América Latina e conceder acesso ao aplicativo, e para determinar
      informações relevantes sobre feriados e promoções, podendo ser
      compartilhadas com os usuários do aplicativo;<br /><br />
      E compartilhá-lo com os prestadores de serviços que operam a
      infraestrutura técnica e relacionados ao aplicativo VSA, como serviços de
      TI e segurança, desde que tenham acordado restrições de privacidade
      semelhantes às desta Política de Privacidade, assinando acordos de
      proteção de dados ou a adoção de compromissos semelhantes.<br /><br />
      6. Segurança e proteção<br />
      Temos o compromisso de proteger os dados pessoais do Usuário. Tomamos
      todas as medidas técnicas e organizacionais apropriadas para ajudar a
      proteger a segurança de seus dados pessoais, protegê-los de acesso não
      autorizado, alteração ou destruição e manter a precisão dos dados. Para
      obter mais detalhes, consulte a Declaração de Política de Privacidade
      Global da Fluke em: https://www.fluke.com/en-us/fluke/privacy-policy<br /><br />
      Suas informações são armazenadas em um servidor seguro e sempre que for
      necessário identificar ou fornecer informações confidenciais, os dados
      serão transmitidos entre o seu dispositivo e o aplicativo Fluke por meio
      de uma conexão segura.<br /><br />
      No entanto, lembre-se de que nenhum sistema é 100% seguro. Sua senha
      protege sua conta de usuário. Recomendamos que você sempre use uma senha
      forte que seja exclusiva para sua conta do aplicativo VSA e nunca
      compartilhe sua senha com ninguém.<br /><br />
      Todas as informações coletadas nos aplicativos serão armazenadas em nosso
      banco de dados sem interferência humana. Além disso, todas as análises
      realizadas com suas informações individuais também serão realizadas por
      processamento eletrônico, e os resultados apresentados serão sempre
      resumos estatísticos, nos quais o seu nome ou qualquer outra informação
      pessoal nunca aparecerá.<br /><br />
      7. Seus direitos<br />
      A legislação brasileira concede certos direitos aos indivíduos em relação
      aos seus dados pessoais. Portanto, os direitos abaixo são concedidos a
      todos os usuários do aplicativo VSA, exceto conforme limitado pela
      legislação aplicável:<br /><br />
      Direito de confirmação: o direito de confirmar a existência do seu
      tratamento de dados pessoais por nós;<br /><br />
      Direito de acesso: o direito de ser informado e solicitar acesso aos dados
      pessoais que processamos sobre você;<br /><br />
      Direito de retificação: direito de solicitar que modifiquemos ou
      atualizemos seus dados pessoais quando eles estiverem imprecisos ou
      incompletos;<br /><br />
      Direito ao anonimato: o direito de tornar anônimo, bloquear ou excluir
      qualquer informação desnecessária ou excesso de dados ou dados processados
      ​​em violação à legislação brasileira;<br /><br />
      Direito à portabilidade dos dados: o direito de solicitar uma cópia dos
      seus dados pessoais em formato eletrónico e o direito de transmitir esses
      dados pessoais para utilização no serviço de outra parte;<br /><br />
      Direito de apagamento: o direito de solicitar que apagemos seus dados
      pessoais que estão sendo processados ​​com base em seu consentimento;<br /><br />
      Direito à informação sobre compartilhamento: o direito de solicitar
      informações de entidades públicas e privadas com as quais o controlador de
      dados compartilhou os dados;<br /><br />
      Direito à informação sobre o consentimento: direito a solicitar informação
      sobre a possibilidade de não dar o consentimento e sobre as consequências
      da recusa; Direito de revogar o consentimento: o direito de revogar o
      consentimento previamente concedido.<br /><br />
      Se você tiver alguma dúvida sobre sua privacidade, seus direitos ou como
      exercê-los, entre em contato com nosso Diretor de Proteção de Dados por
      qualquer um dos métodos listados abaixo:<br /><br />
      Email: marketing@fluke. com.br<br />
      Telefone: +55 11 3530-89018<br /><br />
      8. Mudanças nesta política<br />
      Iremos revisar e atualizar esta Política periodicamente à luz das mudanças
      nas práticas de negócios, tecnologia e requisitos legais. Se fizermos
      quaisquer alterações significativas ou materiais, você será notificado por
      e-mail.<br /><br />
      9.Como entrar em contato conosco<br />
      Se você tiver dúvidas ou preocupações, ou deseja exercer seus direitos de
      privacidade, convidamos você a entrar em contato com nosso Diretor de
      Proteção de Dados por qualquer um dos métodos listados abaixo:<br /><br />
      Email: marketing@fluke.com.br<br />
      Telefone: +55 11 3530-8901
    </div>

    <div class="text-wrap" v-if="$i18n.locale == 'en'">
      Privacy Policy<br /><br />
      We are FLUKE DO BRASIL LTDA., a company duly organized and existing under
      the laws of Brazil, with head offices at Avenida Maria Coelho Aguiar, no.
      215, Bloco F, Piso Panamby, Loja 84C, Jardim São Luís, CEP 05805-000, in
      the city of São Paulo, State of São Paulo, enrolled in the taxpayer under
      number 02.370.758/0001-47 (“Fluke”, “we” or “us”).<br /><br />
      Your privacy and the security of your personal data is very important to
      us. In this Privacy Policy (or simply “Policy”) we explain what
      information and personal data we collect from users of the Virtual Sales
      Assistant application and all its subdomains, programs and extensions
      (“VSA app”), why we collect, security measures and other relevant
      information.<br /><br />
      We have prepared a table with a short description of the terms of this
      Policy. It does not describe all the content of this Policy, but only a
      summary. So please read the entire Policy to understand how we manage and
      protect your privacy and personal data while using VSA app.<br /><br />
      Summary table:<br />
      1. Scope of application and effective date<br />
      We explain to whom does this Policy apply and the effective date of the
      Policy.<br /><br />
      2 Information we collect from you and purposes<br />
      We explain the categories of information and personal data we collect from
      VSA app users, which of them are strictly necessary for using the app and
      which are not, why we collect it and how we use it.<br /><br />
      3. Sharing your personal data<br />
      We explain that we may share your data and information with:<br />
      Fluke’s companies outside of Brazil; <br />
      Services providers which operate technical and infrastructure related to
      VSA app; <br />
      Law enforcement and data protection authorities; and<br />
      A third party only with User’s prior consent.<br /><br />
      Please read this section for further information.<br /><br />
      4. Data correction, retention and deletion<br />
      We explain how to correct or update any personal data, the period of
      retention of your information and situations for its deletion.<br /><br />
      5. Transfer to other countries<br />
      We explain in what situations your personal data may be transferred to
      other countries.<br /><br />
      6. Security and protection<br />
      We explain the measures that are taken by us to safeguard your personal
      data.<br /><br />
      7. Your rights<br />
      We list all personal data rights afforded to individuals in Brazil by
      Brazilian Law<br /><br />
      8. Changes to this Policy<br />
      We explain what will occur in case of a modification of the terms of this
      Policy.<br /><br />
      9. How to contact us<br />
      We provide our contact details.<br /><br />
      1. Scope of application and effective date<br />
      This Policy is applicable to you if you are a user of VSA app in Brazil.
      Please access this Terms of Use to understand the applicable conditions
      for using VSA app.<br /><br />
      VSA app was developed for Fluke sales partners in Brazil and Latin
      America, including distributors and sales representatives, and it is not
      directed to minors under the age of 18, so we do not knowingly collect or
      sell any data of minors.<br /><br />
      This Privacy Policy is effective as of February 10, 2020.<br /><br />
      2. Information we collect and purposes<br />
      In this section you will find the categories of personal data and
      information we will collect from you and from the use of VSA app from your
      device and its purposes. And you will also learn which of them you really
      need to provide for using the app and which of them are optional.<br /><br />
      It is important for you to understand that you have choices regarding the
      personal data we collect. You may control what information you will
      provide and what you will not, depending on what you want from VSA app.<br /><br />
      We retain User data and information only for as long as your account is
      active or as needed to provide you services. We also retain and use your
      information as necessary to comply with our legal obligations.<br /><br />
      Information Users provide directly to us<br />
      You will find below the personal data and information you may provide to
      us: Necessary data for using VSA app<br /><br />
      Categories of data:<br /><br />
      USER DATA<br />
      First and Last Name<br />
      E-Mail address<br />
      Password<br /><br />
      Purposes: We collect this information to enable you to create an User
      account, access VSA app, to enable application browsing, enable usage of
      the services provided by VSA app and contact you. Without providing the
      information above it will not be possible to access or use VSA app.<br /><br />
      Data for increasing your experience with VSA app<br /><br />
      Category of data:<br /><br />
      USER DATA<br />
      Purposes:<br />
      Phone number<br />
      Company name and location (country, zip code, city, state)<br />
      Company role<br />
      Additional phone number<br /><br />
      DATA FROM USER’S DEVICE AND USAGE OF THE APP<br />
      Lugar<br />
      Camera<br />
      Phonebook<br />
      Fingerprint<br />
      Network<br />
      Phone Calls<br /><br />
      We collect this information in order to increase your experience and
      enable additional features and functionalities of VSA app, such as calling
      a telephone number directly from the app.<br /><br />
      This information is not necessary for using VSA app, but it may increase
      your experience while using it<br /><br />
      We will always request your consent before using this data.<br /><br />
      Information we automatically collect from your use of VSA app<br /><br />
      You will find below the personal data and information we automatically
      collect from any User of VSA app:<br />
      Necessary data for using VSA app<br /><br />
      Categories of data:<br /><br />
      TECHNICAL AND USAGE INFORMATION<br />
      IP address, local and time of access<br />
      Device information (type, ID, model and manufacturer)<br />
      Application version<br /><br />
      Purposes:<br /><br />
      We collect this information in order to enable application browsing and
      complying with legal obligations.<br />
      Data for increasing your experience with VSA app<br /><br />
      Categories of data:<br /><br />
      TECHNICAL AND USAGE INFORMATION<br />
      Cookies and similar technologies for tracking your usage of the app<br />
      Purposes: We collect this information to keep you logged in, to remember
      your preferences, and to identify you and your device.<br />
      We will always request your consent before using this data.<br /><br />
      Controlling your personal data<br />
      You can manage your data, in order to manage how Fluke uses your personal
      data, updating it, deleting part of it or deleting your account. Please
      access your profile at the VSA control panel.<br /><br />
      Acesse seu perfil no painel do VSA.<br /><br />
      About your data<br />
      It is important to explain that according to Brazilian Law “Personal data”
      is any information related to an identified or identifiable natural
      person. Some data collected by us such as name, email and IP (Internet
      Protocol) address are personal data.<br />
      However, it does not include aggregated, non-personally identifying
      information that does not identify a User or cannot otherwise be
      reasonably linked or connected with him/her. We may use such aggregated,
      non-personally identifying information to operate, analyze, improve, and
      optimize VSA app.<br /><br />
      3. Sharing your personal data<br />
      We may share User’s personal data or private information with:<br /><br />
      Fluke’s group of companies outside of Brazil, including Fluke Corporation
      headquartered at 6920 Seaway Blvd., Everett, WA 98203, United States, and
      Fluke’s local distributors in Latin America, for the purpose of verifying
      that the User is part of the Fluke Latin America sales channel and
      granting access to the app, and to determine local relevant information
      around holidays and promotions and may be shared back to the app Users;<br /><br />
      Services providers which operate technical and infrastructure related to
      VSA app, such as IT and security services, provided they have agreed to
      privacy restrictions similar to the ones in this Privacy Policy by signing
      data protection agreements or making similar commitments;<br /><br />
      Law enforcement and data protection authorities, to comply with legal
      obligations; and/or<br /><br />
      A third party only with User’s prior consent.<br /><br />
      We do not sell your personal data or private information.<br /><br />
      4. Data correction, retention and deletion<br />
      In case you want to correct, update or delete any personal information,
      please contact marketing@fluke.com.br.<br /><br />
      Even after deletion of your personal data we may retain it using secured
      measures for complying with legal obligations or in other situation
      permitted by Brazilian Law.<br /><br />
      5. Transfer to other countries<br />
      We may transfer User’s personal data or private information to other
      countries, in order to:<br /><br />
      Share it with Fluke’s group of companies outside of Brazil, including
      Fluke Corporation headquartered at 6920 Seaway Blvd., Everett, WA 98203,
      United States,, and Fluke’s local distributors in Latin America, for the
      purpose of verifying that the User is part of the Fluke Latin America
      sales channel and granting access to the app, and to determine local
      relevant information around holidays and promotions and may be shared back
      to the app Users;<br /><br />
      And share it with services providers which operate technical and
      infrastructure related to VSA app, such as IT and security services,
      provided they have agreed to privacy restrictions similar to the ones in
      this Privacy Policy by signing data protection agreements or making
      similar commitments.<br /><br />
      6. Security and protection<br />
      We are committed to protecting User’s personal data. We take all
      appropriate technical and organizational measures to help protect the
      security of your personal data, protect it from unauthorized access,
      alteration, or destruction, and maintain data accuracy. For more details
      please see Fluke’s Global Privacy Policy Statement at:
      https://www.fluke.com/en-us/fluke/privacy-policy<br /><br />
      Your information is stored on a secure server, and whenever there is a
      need to identify or provide sensitive information, data will be
      transmitted between your device and the Fluke application via a secure
      connection.<br /><br />
      However, please note that no system is ever 100% secure. Your password
      protects your User account. We recommend you always use a strong password
      that is unique to your VSA app account and never share your password with
      anyone.<br /><br />
      All information collected in the apps will be stored in our database
      without human interference. In addition, all analyzes performed with your
      individual information will also be done by electronic processing, and the
      results presented will always be statistical summaries, in which your name
      or any other personal data will never appear.<br /><br />
      7. Your rights<br />
      A legislação brasileira concede certos direitos aos indivíduos em relação
      aos seus dados pessoais. Portanto, os direitos abaixo são concedidos a
      todos os usuários do aplicativo VSA, exceto conforme limitado pela
      legislação aplicável:<br /><br />
      Right of confirmation: the right to confirm the existence of your personal
      data processing by us;<br /><br />
      Right of access: the right to be informed of, and request access to, the
      personal data we process about you;<br /><br />
      Right to rectification: the right to request that we amend or update your
      personal data where it is inaccurate or incomplete;<br /><br />
      Right of anonymization: the right of anonymization, blocking or
      elimination of any unnecessary or excessive data or of data processed in
      noncompliance with Brazilian Law;<br /><br />
      Right to data portability: the right to request a copy of your personal
      data in electronic format and the right to transmit that personal data for
      use in another party’s service;<br /><br />
      Right to data portability: the right to request a copy of your personal
      data in electronic format and the right to transmit that personal data for
      use in another party’s service;<br /><br />
      Right to information about sharing: the right to request the information
      of the public and private entities with which the controller shared the
      data;<br /><br />
      Right to information regarding consent: the right to request information
      on the possibility of not providing consent and on the consequences of the
      denial;<br /><br />
      Right to revoke consent: the right of revocation of the consent previously
      provided.<br /><br />
      If you have any questions about your privacy, your rights or how to
      exercise them, please contact our Data Protection Officer by any of the
      methods listed below:<br /><br />
      Email: marketing@fluke. com.br<br />
      Telephone: +55 11 3530-89018<br /><br />
      8. Changes to this Policy<br />
      We will review and update this Policy periodically in light of changing
      business practices, technology, and legal requirements. If we make any
      significant or material change, you will be notified via email.<br /><br />
      9. How to contact us<br />
      If you have any questions or concerns, or wish to exercise your privacy
      rights, we invite you to contact our Data Protection Officer by any of the
      methods listed below:<br /><br />
      Email: marketing@fluke.com.br<br />
      Telephone: +55 11 3530-8901
    </div>

    <div class="text-wrap" v-if="$i18n.locale == 'es'">
      Política de Privacidad<br /><br />
      Somos FLUKE DO BRASIL LTDA., Una empresa debidamente organizada y
      existente bajo las leyes de Brasil, con sede en Avenida Maria Coelho
      Aguiar, no. 215, Bloco F, Piso Panamby, Tienda 84C, Jardim São Luís, CEP
      05805-000, en la ciudad de São Paulo, Estado de São Paulo, inscrito en el
      contribuyente con el número 02.370.758 / 0001-47 (“Fluke”, "Nosotros" o
      "nuestro").<br /><br />
      Su privacidad y la seguridad de sus datos personales son muy importantes
      para nosotros. En esta Política de privacidad (o simplemente "Política")
      explicamos qué información y datos personales recopilamos de los usuarios
      de la aplicación Asistente de Ventas Virtual y todos sus subdominios,
      programas y extensiones ("VSA app"), por qué recopilamos, medidas de
      seguridad y otra información relevante.<br /><br />
      Hemos preparado una tabla con una breve descripción de los términos de
      esta Política. No describe todo el contenido de esta Política, sino solo
      un resumen. Por lo tanto, lea la Política completa para comprender cómo
      administramos y protegemos su privacidad y sus datos personales mientras
      usa VSA app.<br /><br />
      Tabla de resumen:<br />
      1. Ámbito de aplicación y fecha de vigencia<br />
      Explicamos a quién se aplica esta Política y la fecha de vigencia de la
      Política.<br /><br />
      2 Información que recopilamos de usted y propósitos<br />
      Explicamos las categorías de información y datos personales que
      recopilamos de los usuarios de la aplicación VSA, cuál de son
      estrictamente necesarios para usar la aplicación y cuáles no lo son, por
      qué la recopilamos y cómo la usamos.<br /><br />
      3. Compartir sus datos personales<br />
      Le explicamos que podemos compartir sus datos e información con: empresas
      de Fluke fuera de Brasil; proveedores de servicios que operan técnicas e
      infraestructura relacionada con la aplicación VSA; Autoridades policiales
      y de protección de datos; y un tercero solo con el consentimiento previo
      del Usuario<br /><br />
      Por favor, lea esta sección para obtener más información<br /><br />
      4. Corrección, retención y eliminación de datos<br />
      Explicamos cómo corregir o actualizar cualquier dato personal, el período
      de retención de su información y las situaciones para su eliminación.<br /><br />
      5. Transferir a otros países<br />
      Explicamos en qué situaciones sus datos personales pueden ser transferidos
      a otros países.<br /><br />
      6. Seguridad y protección<br />
      Explicamos las medidas que tomamos para salvaguardar sus datos
      personales.<br /><br />
      7. Sus derechos<br />
      Enumeramos todos los derechos de datos personales otorgados a las personas
      en Brasil por la Ley brasileña<br /><br />
      8. Cambios a esta Política<br />
      Explicamos lo que ocurrirá en caso de una modificación de los términos de
      esta Política.<br /><br />
      9. Cómo contactarnos<br />
      Proporcionamos nuestros datos de contacto.<br /><br />
      1. Ámbito de aplicación y fecha de vigencia<br />
      Esta Política es aplicable a usted si es usuario de VSA app en Brasil.
      Acceda a estos Términos de uso para comprender las condiciones aplicables
      para el uso de VSA app.<br /><br />
      La aplicación VSA fue desarrollada para socios de ventas de Fluke en
      Brasil y América Latina, incluidos distribuidores y representantes de
      ventas, y no está dirigida a menores de 18 años, por lo que no recopilamos
      ni vender cualquier dato de menores.<br /><br />
      Esta Política de privacidad es efectiva a partir del 10 de febrero de
      2020.<br /><br />
      2. Información que recopilamos y propósitos<br />
      En esta sección, encontrará las categorías de datos personales e
      información que recopilaremos de usted y del uso de la aplicación VSA
      desde su dispositivo y sus propósitos. Y también aprenderá cuáles de ellos
      realmente necesita proporcionar para usar la aplicación y cuáles son
      opcionales.<br /><br />
      Es importante que comprenda que tiene opciones con respecto a los datos
      personales que recopilamos. Puede controlar qué información proporcionará
      y qué no, dependiendo de lo que desee de la aplicación VSA.<br /><br />
      Retenemos los datos y la información del usuario solo mientras su cuenta
      esté activa o según sea necesario para brindarle servicios. También
      retenemos y utilizamos su información según sea necesario para cumplir con
      nuestras obligaciones legales.<br /><br />
      Información que los usuarios nos proporcionan directamente<br />
      A continuación, encontrará los datos personales y la información que nos
      puede proporcionar: Datos necesarios para utilizar la aplicación VSA<br /><br />
      Categorías de datos:<br /><br />
      DATOS DEL USUARIO<br />
      Nombre y apellido<br />
      Dirección de correo electrónico<br />
      Contraseña<br /><br />
      Propósitos: recopilamos esta información para que pueda crear una cuenta
      de usuario, acceder a la aplicación VSA, para habilitar la exploración de
      aplicaciones, habilitar el uso de los servicios proporcionados por VSA y
      contactarlo. Sin proporcionar la información anterior, no será posible
      acceder o utilizar la aplicación VSA.<br /><br />
      Datos para aumentar su experiencia con la aplicación VSA<br /><br />
      Categoría de datos:<br /><br />
      DATOS DEL USUARIO<br />
      Propósitos:<br />
      Número de teléfono<br />
      Nombre de la empresa y ubicación (país, código postal, ciudad, estado)<br />
      Función en la empresa<br />
      Número de teléfono adicional<br /><br />
      DATOS DEL DISPOSITIVO DEL USUARIO Y USO DE LA APLICACIÓN<br />
      Cámara<br />
      Agenda telefónica<br />
      Huella digital<br />
      Network<br />
      Llamadas telefónicas<br /><br />
      Recopilamos esta información para aumentar su experiencia y habilitar
      características y funcionalidades adicionales de la aplicación VSA, como
      llamar a un número de teléfono directamente desde la aplicación.<br /><br />
      Esta información no es necesaria para usar la aplicación VSA, pero puede
      aumentar su experiencia al usarla.<br /><br />
      Siempre solicitaremos su consentimiento antes de utilizar estos datos.<br /><br />
      Información que recopilamos automáticamente de su uso de la aplicación
      VSA<br /><br />
      A continuación, encontrará los datos personales y la información que
      recopilamos automáticamente de cualquier usuario de la aplicación VSA:<br />
      Datos necesarios para usar la aplicación VSA<br /><br />
      Categorías de datos:<br /><br />
      INFORMACIÓN TÉCNICA Y DE USO<br />
      Dirección IP, local y hora de acceso<br />
      Información del dispositivo (tipo, ID, modelo y fabricante)<br /><br />
      Versión de la aplicación Finalidades:<br /><br />
      Recopilamos esta información para permitir la navegación de la aplicación
      y el cumplimiento de las obligaciones legales.<br />
      Datos para aumentar su experiencia con la aplicación VSA<br /><br />
      Categorías de datos:<br /><br />
      INFORMACIÓN TÉCNICA Y DE USO<br />
      Cookies y tecnologías similares para rastrear su uso de la aplicación<br />
      Propósitos: Recopilamos esta información para mantenerlo conectado,
      recordar sus preferencias e identificarlo a usted y a su dispositivo.<br />
      Solicite siempre su consentimiento antes de utilizar estos datos.<br /><br />
      Control de sus datos personales<br />
      Puede gestionar sus datos para gestionar cómo Fluke utiliza sus datos
      personales, actualizándolos, borrando parte de ellos o borrando su
      cuenta.<br /><br />
      Acceda a su perfil en el panel de control de VSA.<br /><br />
      Sobre sus datos<br />
      Es importante explicar que de acuerdo con la ley brasileña, “datos
      personales” es cualquier información relacionada con una persona física
      identificada o identificable. Algunos datos recopilados por nosotros, como
      el nombre, el correo electrónico y la dirección IP (Protocolo de
      Internet), son datos personales.<br />
      Sin embargo, no incluye información de identificación no personal agregada
      que no identifica a un Usuario o que de otra manera no puede vincularse o
      conectarse razonablemente con él. Podemos usar dicha información de
      identificación no personal agregada para operar, analizar, mejorar, y
      optimizar la aplicación VSA.<br /><br />
      3. Compartir sus datos personales<br />
      Podemos compartir los datos personales o la información privada del
      Usuario con:<br /><br />
      El grupo de empresas de Fluke fuera de Brasil, incluida Fluke Corporation,
      con sede en 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, y los
      distribuidores locales de Fluke en América Latina, por el propósito de
      verificar que el Usuario es parte del canal de ventas de Fluke
      Latinoamérica y otorgar acceso a la aplicación, y para determinar la
      información local relevante sobre vacaciones y promociones y que pueda ser
      compartida con los Usuarios de la aplicación;<br /><br />
      Proveedores de servicios que operan técnicos y relacionados con la
      infraestructura. a la aplicación VSA, como los servicios de seguridad y
      TI, siempre que hayan aceptado restricciones de privacidad similares a las
      de esta Política de privacidad mediante la firma de acuerdos de protección
      de datos o compromisos similares;<br /><br />
      Autoridades policiales y de protección de datos, para cumplir con las
      obligaciones legales;<br /><br />
      Y/o un tercero solo con el consentimiento previo del Usuario.<br /><br />
      NNo vendemos sus datos personales o información privada.<br /><br />
      4. Corrección, retención y eliminación de datos<br />
      En caso de que desee corregir, actualizar o eliminar cualquier información
      personal, comuníquese con marketing@fluke.com.br.<br /><br />
      Incluso después de la eliminación de sus datos personales, podemos
      conservarlos utilizando medidas seguras para cumplir con las obligaciones
      legales o en otra situación permitida por la ley brasileña.<br /><br />
      5. Transferencia a otros países<br />
      Podemos transferir los datos personales o la información privada del
      Usuario a otros países para:<br /><br />
      Compartirlos con el grupo de empresas de Fluke fuera de Brasil, incluida
      Fluke Corporation con sede en 6920 Seaway Blvd., Everett, WA 98203,
      Estados Unidos, y los distribuidores locales de Fluke en América Latina,
      con el fin de verificar que el Usuario es parte del canal de ventas de
      Fluke para América Latina y otorgar acceso a la aplicación, y para
      determinar información relevante sobre vacaciones y promociones y puede
      ser compartida con los usuarios de la aplicación;<br /><br />
      Y compartirla con proveedores de servicios que operan la infraestructura
      técnica y relacionada con la aplicación VSA, como servicios de TI y
      seguridad, siempre que hayan acordado restricciones de privacidad
      similares a las de esta Política de Privacidad mediante la firma de
      acuerdos de protección de datos o la adopción de compromisos similares.<br /><br />
      6. Seguridad y protección<br />
      Estamos comprometidos a proteger los datos personales del Usuario. Tomamos
      todas las medidas técnicas y organizativas apropiadas para ayudar a
      proteger la seguridad de sus datos personales, protegerlos del acceso no
      autorizado, alteración o destrucción, y mantener la precisión de los
      datos. Para obtener más detalles, consulte la Declaración de política de
      privacidad global de Fluke en: https://www.fluke.com/en-us/
      fluke/privacy-policy.<br /><br />
      Su información se almacena en un servidor seguro y siempre que sea
      necesario identificar o proporcionar información confidencial, los datos
      se transmitirán entre su dispositivo y la aplicación Fluke a través de una
      conexión segura.<br /><br />
      Sin embargo, tenga en cuenta que ningún sistema es 100% seguro. Su
      contraseña protege su cuenta de usuario. Le recomendamos que utilice
      siempre una contraseña segura que sea única para la cuenta de su
      aplicación VSA y nunca comparta su contraseña con nadie.<br /><br />
      Toda la información recopilada en las aplicaciones se almacenará en
      nuestra base de datos sin interferencia humana. Además, todos los análisis
      realizados con su información individual también se realizarán mediante
      procesamiento electrónico, y los resultados presentados serán siempre
      resúmenes estadísticos, en los que nunca aparecerá su nombre ni ningún
      otro dato personal.<br /><br />
      7. Sus derechos<br />
      La ley brasileña otorga ciertos derechos a las personas en relación con
      sus datos personales. Por lo tanto, los derechos a continuación se otorgan
      a todos los usuarios de la aplicación VSA, excepto en los casos limitados
      por la ley aplicable:<br /><br />
      Derecho de confirmación: el derecho a confirmar la existencia de su
      procesamiento de datos personales por nuestra parte;<br /><br />
      Derecho de acceso: el derecho a ser informado y solicitar acceso a los
      datos personales que procesamos sobre usted;<br /><br />
      Derecho de rectificación: derecho a solicitar que modifiquemos o
      actualicemos sus datos personales cuando sean inexactos o incompletos;<br /><br />
      Derecho de rectificación: derecho a solicitar que modifiquemos o
      actualicemos sus datos personales cuando sean inexactos o incompletos;<br /><br />
      Derecho a la portabilidad de datos: el derecho a solicitar una copia de
      sus datos personales en formato electrónico y el derecho a transmitir esos
      datos personales para su uso en el servicio de otra parte;<br /><br />
      Derecho de borrado: el derecho a solicitar que eliminemos sus datos
      personales que se están procesando en base a tu consentimiento;<br /><br />
      Derecho a la información sobre compartir: el derecho a solicitar la
      información de las entidades públicas y privadas con las que el
      responsable del tratamiento compartió los datos;<br /><br />
      Derecho a la información sobre el consentimiento: el derecho a solicitar
      información sobre la posibilidad de no prestar consentimiento y sobre las
      consecuencias de la denegación;<br /><br />
      Derecho a revocar el consentimiento: el derecho de revocación del
      consentimiento otorgado anteriormente.<br /><br />
      Si tiene alguna pregunta sobre su privacidad, sus derechos o cómo
      ejercerlos, comuníquese con nuestro Delegado de Protección de Datos por
      cualquiera de los métodos que se enumeran a continuación:<br /><br />
      Correo electrónico: marketing@fluke. com.br<br />
      Teléfono: +55 11 3530-89018<br /><br />
      8. Cambios a esta Política<br />
      Revisaremos y actualizaremos esta Política periódicamente a la luz de las
      prácticas comerciales cambiantes, la tecnología y los requisitos legales.
      Si realizamos algún cambio significativo o material, se le notificará por
      correo electrónico.<br /><br />
      9. Cómo contactarnos<br />
      Si tiene alguna pregunta o inquietud, o desea ejercer sus derechos de
      privacidad, lo invitamos a comunicarse con nuestro Oficial de Protección
      de Datos por cualquiera de los métodos que se enumeran a continuación:<br /><br />
      Correo electrónico: marketing@fluke.com.br<br />
      Teléfono: +55 11 3530-8901
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$i18n.locale = localStorage.currentLang
  },
  methods: {
    setLanguage(lang) {
      localStorage.currentLang = lang
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    },
  },
};
</script>

<style lang="scss">
.login-links {
  margin: 35px 0;
  &__links {
    text-align: center;
    &__separator {
      opacity: 0.5;
      margin: 0 10px;
    }
    a {
      color: inherit;
    }
  }
  &__languages {
    text-align: center;
    margin-top: 30px;

    & > div {
      font-size: 12px;
      border-radius: 3px;
      display: inline-block;
      padding: 5px 7px;
      margin: 0 5px;
      background-color: #eeeeee;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }

      &.active {
        background-color: #000;
        color: #fff;
      }
    }
  }
}
</style>
